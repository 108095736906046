import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

function login(v, username, password) {
  v.$store
    .dispatch("ship2u/login", {
      email: username,
      password,
    })
    .then(response => {
      console.log(response)
      const { status, userData } = response
      if (status === 200) {
        sessionStorage.setItem("userData", JSON.stringify(userData))
        v.$ability.update(userData.ability)

        v.$router
          .push(getHomeRouteForLoggedInUser(userData.role))
          .then(async () => {
            await v.$store.dispatch("ship2u/parcels")
            await v.$store.dispatch("ship2u/orders")
            v.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `${v.$i18n.t("Welcome")} ${
                  userData.fullName || userData.username
                }`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `${v.$i18n.t("You have successfully logged in as ")}${v.$i18n.t(userData.role)}.${v.$i18n.t("Now you can start to explore!")}`,
              },
            })
          })
          .catch(error => {
            // v.$refs.loginForm.setErrors(error.response.data.error)
            console.log(error)
          })
      } else if (status === 400) {
        v.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "login fail",
            icon: "CoffeeIcon",
            variant: "warning",
            text: response.error.msg,
          },
        })
      }
      // useJwt.setToken(response.data.accessToken)
      // useJwt.setRefreshToken(response.data.refreshToken)
    })
    .catch(e => {
      console.log("e", e)
    })
}

export default login
